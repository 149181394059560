const portfolioData = [
  {
    title: 'React',
    tab: 'react',
    link: '/data/portfolio.json'
  },
  {
    title: 'WordPress',
    tab: 'wordpress',
    link: '/data/portfolio-wordpress.json'
  }
];

export default portfolioData;
